<template>
  <div>
      <v-card class="mb-6">
          <v-card-title>Lotes de importación</v-card-title>
          <ContactBatchesList></ContactBatchesList>
      </v-card>
  </div>
</template>

<script>
import ContactBatchesList from '@/components/ContactBatches/ContactBatchesList.vue'

export default {
  components: {
    ContactBatchesList
  }
}
</script>
