<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-text v-if="loading">
        Cargando...
        <v-progress-linear indeterminate color="black" class="mb-0"></v-progress-linear>
      </v-card-text>
      <v-toolbar class="tool-bar">
        <v-btn icon color="primary" @click="close()">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title class="text-h5 grey lighten-2">{{
          `Lote: ${batch.name}`
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <!-- <div>
        <v-card-subtitle class="pt-4 ml-10">
          {{ `Creada en ${"eee"} por el usuario ${"eee"}` }}
        </v-card-subtitle>
      </div> -->

      <v-divider></v-divider>

      <v-card-text v-if="!loading">
        <v-row class="mt-2">
          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar size="44" color="primary" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiHelpCircleOutline }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">Estado</p>
                <span class="text--primary text-xl font-weight-bold" v-if="batch.status">
                  {{ batch.status ? batch.status.label : '-' }}
                </span>

                <p class="text-s mb-0">Estado de Google</p>
                <span class="text--primary text-xl font-weight-bold" v-if="batch.google_status">
                  {{ batch.google_status ? batch.google_status.label : '-' }}
                </span>

                <p class="text-s mb-0">Borrar los contactos que no pertenecen a este lote</p>
                <span class="text--primary text-l font-weight-bold">
                  {{ batch.delete_all ? 'Si' : 'No'}}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar size="44" color="warning" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiCalendarQuestion }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">Fecha de creación</p>
                <span class="text--primary text-xl font-weight-bold">
                  {{ batch.created_at }}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar size="44" color="info" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiAccountMultiplePlus }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">Contactos procesados</p>
                <span class="text--primary text-xl font-weight-bold">
                  {{ `${batch.processed_contacts} de ${batch.total_contacts}` }}
                </span>

                <p class="text-s mb-0">Contactos sincronizados</p>
                <span class="text--primary text-l font-weight-bold">
                  {{ batch.total_google_sync ? `${batch.total_google_sync}` : 'La empresa seleccionada no tiene cuenta de google asociada o el lote no fue sincronizado' }}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar size="44" :color=" !fullImportCompleted ? 'success' : 'error' " rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{icons.mdiEyeOutline }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0"> Informe del procesamiento</p>
                <div v-if="!fullImportCompleted">
                  <span class="text--primary text-xl font-weight-bold"> Sin problemas</span>
                </div>
                <div v-else class="text--primary text-xl font-weight-bold">
                  <span>{{ `Fallidos ` }}</span>
                  <v-btn icon x-small color="primary" @click="downloadFile(batch.csv_contacts_failed)">
                    <v-icon>{{ icons.mdiFileRemoveOutline }}</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="error"
                        class="ml-1"
                        small
                        :disabled="!batch.sync_error_logs"
                        v-bind="attrs"
                        v-on="on"
                        @click="$refs.sync_error_list_modal.openModal(batch.id)"
                      >
                        <v-icon>{{ icons.mdiClipboardAlertOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Ver registro de errores de sincronización.
                    </span>
                  </v-tooltip>
                </div>
              </div>
            </div>
          </v-col>

        </v-row>
      </v-card-text>

      <ContactsList
      ref="contact_list"
      :parent-element="'batches'"
      :batch="batch"
      />

    </v-card>

    <AlertPopUp ref="alert_pop_up" />
    <SyncErrorListModal ref="sync_error_list_modal" />
  </v-dialog>
</template>
<script>
import { ref } from "@vue/composition-api";
import AlertPopUp from "../AlertPopUp.vue";
import SyncErrorListModal from './SyncErrorListModal.vue';
import {
  mdiEyeOutline,
  mdiProgressCheck,
  mdiClose,
  mdiHelpCircleOutline,
  mdiCalendarQuestion,
  mdiWhatsapp,
  mdiReloadAlert,
  mdiSendOutline,
  mdiDownload,
  mdiEyePlusOutline,
  mdiPlus,
  mdiFileDownloadOutline,
  mdiFileRemoveOutline,
  mdiAccountMultiplePlus,
  mdiClipboardAlertOutline
} from "@mdi/js";
import ContactsList from "../Contacts/ContactsList.vue";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
    };
  },
  methods: {
    async getContactBatch(id) {
      this.loading = true;
      try {
        const response = await this.$api.getContacBatch(id);
        this.batch = response.data
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    downloadFile(csv_contacts_failed){
      window.open(csv_contacts_failed.url, '_blank')
    },
    open(item) {
      this.getContactBatch(item.id)
      this.dialog = true;
    },
    close() {
      this.batch = {};
      this.dialog = false;
      this.$refs.contact_list.clearContactList();
    },
  },
  computed: {
    fullImportCompleted(){
      return this.batch.csv_contacts_failed
    }
  },
  setup() {
    const batch = ref({})
    return {
      batch,
      icons: {
        mdiEyeOutline,
        mdiProgressCheck,
        mdiClose,
        mdiHelpCircleOutline,
        mdiCalendarQuestion,
        mdiWhatsapp,
        mdiReloadAlert,
        mdiSendOutline,
        mdiDownload,
        mdiEyePlusOutline,
        mdiPlus,
        mdiFileDownloadOutline,
        mdiFileRemoveOutline,
        mdiAccountMultiplePlus,
        mdiClipboardAlertOutline
      },
    }
  },
  components: { AlertPopUp, ContactsList, SyncErrorListModal },
}
</script>
<style scoped lang="scss">
.tool-bar {
  box-shadow: 0 0px 1px 0px rgba(94, 86, 105, 0.56) !important;
}
</style>
