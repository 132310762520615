<template>
  <div>
    <v-card>
      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- Search -->
        <v-text-field
        v-model="searchQuery"
        @keyup="handleSearch"
        placeholder="Buscar por nombre"
        outlined
        hide-details
        dense
        class="contact-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">

          <v-btn
          color="secondary"
          class="mb-4 me-3 px-2 no-uppercase"
          @click="exportCsvSample()"
          >
            <v-icon>{{ icons.mdiFileDownload }}</v-icon>
            <span>Exportar CSV Ejemplo</span>
          </v-btn>

          <v-btn
          color="warning"
          class="mb-4 me-3 px-2 no-uppercase"
          @click="$refs.import_contact_modal.open()"
          >
            <v-icon>{{ icons.mdiFileUploadOutline }}</v-icon>
            <span>Importar Lote</span>
          </v-btn>

        </div>
      </v-card-text>

      <v-data-table
      :headers="tableColumns"
      :items="contactsListTable"
      :page="current_page"
      :server-items-length="totalContactsListTable"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      @update:page="changingContactsPage($event)"
      @update:items-per-page="changingContactsItemsPerPage($event)"
      >

        <!-- NO data -->
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No hay
              <strong>Lotes de importación</strong>
              disponibles para esta búsqueda
            </v-list-item-title>
          </v-list-item>
        </template>

        <!-- name -->
        <template #[`item.name`]="{item}">
          <v-btn
            class="no-uppercase"
            text
            @click="$refs.contact_batch_detail.open(item)"
          >
            {{ item.name }}
          </v-btn>
        </template>

        <!-- name -->
        <template #[`item.processed_contacts`]="{item}">
          <div>
            <span :class="item.processed_contacts === item.total_contacts ? 'font-weight-bold': ''">{{ item.processed_contacts }}</span>
            <span>{{ ' de ' }}</span>
            <span class="font-weight-bold">{{ item.total_contacts }}</span>
          <!-- {{ `${item.processed_contacts} de ${item.total_contacts}` }} -->
          </div>
        </template>

        <template #[`item.google_status`]="{ item }">
          <v-btn
            class="no-uppercase status-container pa-2"
            depressed
            :color="googleStatusColorParser(item.google_status.id)"
            max-height="26px"
            min-width="0"
          >
            <span style="color: white">{{ item.google_status.label }}</span>
          </v-btn>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-btn
            class="no-uppercase status-container pa-2"
            depressed
            :color="statusColorParser(item.status.id)"
            max-height="26px"
            min-width="0"
            v-if="
              (item.percentage_progress_creation === 0 && item.status.id !== 0) ||
              item.percentage_progress_creation === 100 || item.status.id === 1
            "
          >
            <span style="color: white">{{ item.status.label }}</span>
          </v-btn>
          <div
            v-else
            class="rounded-lg relative"
            style="
              width: 100%;
              background-color: #bdbdbd;
              height: 26px;
              position: relative;
            "
          >
            <div
              class="rounded-lg"
              :style="{
                width: item.percentage_progress_creation + '%',
                'background-color':
                  item.percentage_progress_creation < 25
                    ? '#F44336'
                    : item.percentage_progress_creation >= 25 &&
                      item.percentage_progress_creation <= 50
                    ? '#FF9800'
                    : item.percentage_progress_creation > 50 &&
                      item.percentage_progress_creation <= 75
                    ? '#FFEB3B'
                    : '#4CAF50',
                height: '26px',
              }"
            ></div>
            <span
              style="
                position: absolute;
                top: 2px;
                bottom: 0;
                left: 0;
                right: 0;
                color: white;
              "
              >{{ item.percentage_progress_creation }}%</span
            >
          </div>
        </template>

        <template #[`item.delete`]="{item}">
					<v-btn
						icon
            @click="$refs.delete_batch_modal.open(item)"
					>
						<v-icon class="error--text">
							{{ icons.mdiDeleteOutline }}
						</v-icon>
					</v-btn>
        </template>

        <template #[`item.google_sync`]="{item}">
					<v-btn icon color="primary" @click="googleSync(item.id)" :disabled="!item.google_account || item.google_status.id === 1 || item.status.id !== 1">
            <v-icon>
              {{ icons.mdiLogin }}
            </v-icon>
          </v-btn>
        </template>

      </v-data-table>

    </v-card>

    <ContactBatchDetail
    ref="contact_batch_detail"
    />

    <ImportContactBatch
    ref="import_contact_modal"
    @reload-contact-list="getContactBatches()"
    @display-alert="showCreationAlert"
    />

    <DeleteContactBatch
    ref="delete_batch_modal"
    @reload-contact-list="getContactBatches()"
    @display-alert="showCreationAlert"
    />

    <alert-pop-up ref="alert_pop_up" />
  </div>
</template>

<script>
const debounce = require('debounce');
import { ref } from '@vue/composition-api';
import AlertPopUp from '@/components/AlertPopUp.vue';
import ImportContactBatch from './ImportContactBatch.vue';
import DeleteContactBatch from './DeleteContactBatch.vue';
import ContactBatchDetail from './ContactBatchDetail.vue';
import {
  mdiAccountPlus,
  mdiFileDownload,
  mdiFileUploadOutline,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiTag,
  mdiDownload,
  mdiLogin
} from '@mdi/js'

export default {
  components:{
    AlertPopUp,
    ImportContactBatch,
    DeleteContactBatch,
    ContactBatchDetail
},
  data:() => {
    return {
      current_page: 1,
      page_size: 10,
      companyTagsData:[],
      contactsAvailableExport: false,
      timer: null,
      creatingColor: "rgb(146, 149, 143)",
      completedColor: "rgb(86, 202, 0)",
    }
  },
  mounted() {
    if(this.getSelectedCompany) {
      this.getContactBatches()
    }
  },
  methods: {
    async getContactBatches() {
      this.loading = true
      try {
        const query = {
          company_id: this.getSelectedCompany !== 'all' ? this.getSelectedCompany : '',
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
        }

        const response = await this.$api.getContactBatches(query)

        this.contactsListTable = response.results
        this.totalContactsListTable = response.count

      } catch(error) {
        console.log(error)
      } finally {
        this.loading = false
        this.setTimer()
      }
    },

    handleSearch: debounce(function (e) {
      this.current_page = 1
      this.getContactBatches()
    }, 400),

    changingContactsPage(e) {
      this.current_page = e
      this.getContactBatches()
    },

    changingContactsItemsPerPage(e) {
      this.page_size = e
      this.getContactBatches()
    },

    setTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => {
        this.getContactBatches()
      }, this.contactsListTable.some(el => el.status.id === 0 || el.google_status.id === 1) ? 10000 : 6000000 )
    },

    async exportCsvSample() {
      const sample = true
      const data = await this.$api.exportCsvSample()
      this.clickCreatedLink(data, sample)
    },

    clickCreatedLink(data, sample) {
      const blob = new Blob([data], { type: 'text/html;charset=utf-8;' });
      const url = URL.createObjectURL(blob)
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", sample ? 'Csv Ejemplo.csv' : `Contactos_Empresa_id_${this.getSelectedCompany}.xls`);
      link.click();
    },

    statusColorParser(status) {
      if (status === 0) return this.creatingColor;
      if (status === 1) return this.completedColor;
    },

    googleStatusColorParser(status) {
      if (status === 0) return 'error';
      if (status === 1) return 'primary';
      if (status === 2) return 'success';
    },

    async googleSync(id) {
      try {
        const response = await this.$api.googleSyncBatches(id)
        this.showCreationAlert({message: response.data.message, type: 'success'})
        this.getContactBatches()
      } catch(e) {
        console.log(e)
      }
    },

    showCreationAlert(data) {
      this.$refs.alert_pop_up.showPopUp({ message: data.message, type: data.type })
    }
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
  },
  watch: {
    getSelectedCompany(newValue) {
      if(newValue) {
        this.current_page = 1
        this.page_size = 10
        this.getContactBatches()
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  setup () {
    const totalContactsListTable = ref(1);
    const contactsListTable = ref([]);

    const tableColumns = [
      { text: 'Nombre', value: 'name', align: 'center', sortable: false },
      { text: 'Contactos procesados', value: 'processed_contacts', align: 'center', sortable: false },
      { text: 'Fecha', value: 'created_at', align: 'center', sortable: false },
      { text: 'Estado de Google', value: 'google_status', align: 'center', sortable: false },
      { text: 'Estado', value: 'status', align: 'center', sortable: false },
      { text: 'Eliminar', value: 'delete', align: 'center', sortable: false },
      { text: 'Sincronizar con Google', value: 'google_sync', align: 'center', sortable: false },
    ];
    const loading = ref(false);
    const searchQuery = ref('');

    return {
      tableColumns,
      loading,
      searchQuery,
      totalContactsListTable,
      contactsListTable,
      icons: {
        mdiAccountPlus,
        mdiFileDownload,
        mdiFileUploadOutline,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiTag,
        mdiDownload,
        mdiLogin
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.no-uppercase {
      text-transform: unset !important;
}

.tag-container {
    color: white;
    background-color: rgb(120, 120, 120);
    font-size: 14px;
}

.tag-container:hover {
    cursor: default;
}
</style>
