<template>
  <div>
    <v-dialog v-model="showingImportContactModal" width="auto" @input="close">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Importar lote de contactos
          <!-- Seleccione un archivo .csv o .xls para importar contactos -->
        </v-card-title>

        <v-card-text class="pb-0">
          <v-text-field
            label="Nombre del lote"
            type="text"
            v-model="batchName"
            :rules="[(v) => !!v || 'Campo requerido']"
            outlined
            dense
            required
            @input="error = null"
          ></v-text-field>

          <v-file-input
            v-model="contactFile"
            @change="validateFile"
            webkitRelativePath
            accept=".csv, .xls, .xlsx"
            ref="contact_file_input"
            :rules="[(v) => !!v || 'Campo requerido']"
            label="Seleccione un archivo..."
            hint="Seleccione un archivo .csv o .xls para importar contactos"
            persistent-hint
          ></v-file-input>

          <v-switch
            v-model="contacts_delete"
            :true-value="true"
            :false-value="false"
            @change="error = null"
            hide-details
            label="Borrar contactos que no estén en el lote"
          ></v-switch>
        </v-card-text>
        <div class="d-flex justify-content-center mt-2">
          <span class="error_msg mx-2">
            {{ error }}
          </span>
        </div>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="close()"> CANCELAR </v-btn>
          <v-btn
            ref="send-btn"
            color="success"
            @click="importContact()"
            :disabled="error !== null || contactFile === null || importLoading"
          >
            {{ importLoading ? 'importando ' : 'importar' }}
            <v-progress-circular v-if="importLoading"
              :size="25"
              class="ms-2"
              indeterminate
              color="secondary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  data: () => {
    return {
      showingImportContactModal: false,
      batchName: "",
      contactFile: null,
      error: null,
      importLoading: false,
      contacts_delete: false
    };
  },

  computed: {
    getUserRole() {
      return this.$store.getters["session/getUserRole"]();
    },
    getSelectedCompany() {
      return this.$store.getters["session/getSelectedCompany"]();
    },
  },

  methods: {
    open() {
      this.showingImportContactModal = true;
    },

    close() {
      this.contactFile = null;
      this.batchName = '';
      this.contacts_delete = false;
      this.error = null;
      this.showingImportContactModal = false;
    },

    async importContact() {
      try {
        this.importLoading = true
        const formData = new FormData();

        formData.append("csv_file", this.contactFile);
        formData.append("name", this.batchName);
        formData.append("delete_all", this.contacts_delete);
        formData.append("company_id", this.getSelectedCompany);

        if(formData.entries().next().done) {
          this.$emit("display-alert", { message: 'No se pueden crear lotes en este momento. Revise su conexión a internet', type: 'error' });
        } else {
          const response = await this.$api.createContactBatch(formData);
          if (response.status >= 200 && response.status < 300) {
            this.$emit("reload-contact-list");
            this.$emit("display-alert", { message: 'Se creo un nuevo batch', type: 'success' });
            this.close()
          }
        }
      } catch (e) {
        console.log(e.response?.data.message)
        e.response?.data?.error
          ? (this.error = e.response.data.error)
          : e.response?.data?.name 
          ? (this.error = e.response.data.name[0])
          :e.response?.data?.message
          ? (this.error = e.response.data.message)
          : (this.error = 'El archivo no puede procesarse, por favor descargue el archivo "Exportar CSV Ejemplo');
      } finally {
        this.importLoading = false
      }
    },
    validateFile(file) {
      let allowedExtensions = /(\.csv|\.xls|\.xlsx)$/i;
      if (!!file && !allowedExtensions.exec(file.name)) {
        this.error = "Sólo se permiten archivos .csv, .xls o .xlsx";
      } else {
        this.error = null;
      }
    },
  },
};
</script>
<style scoped>
.error_msg {
  color: red;
  margin-left: 25px;
}
</style>
